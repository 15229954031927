import './style.scss';

function Footer() {
    return (
        <footer className="footer">
            <p className="footer__text">Budget Master - 2021 &copy;</p>
        </footer>
    );
}

export default Footer;